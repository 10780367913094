var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contentPopin",class:{ 'contentPopin--masonry': _vm.useMassonry },on:{"click":function () { return _vm.setPopinOpen(false); }}},[_c('div',{ref:"inner",staticClass:"contentPopin__inner",on:{"click":function($event){$event.stopPropagation();return _vm.onClickStop.apply(null, arguments)}}},[_c('header',{staticClass:"contentPopin__header"},[_c('h2',[_vm._v(_vm._s(_vm.title))]),(_vm.source !== 'embed' && !_vm.isYoutubeSingleGrid)?[(_vm.source === 'grid' && _vm.canDownload)?_c('p',[_c('a',{attrs:{"href":_vm.popinContent.media.originFile.url,"download":""}},[_vm._v(" Download "+_vm._s(_vm.popinContent.media.type)+" ")])]):_vm._e(),(_vm.canDownload && _vm.downloadArchive)?_c('p',[_c('a',{attrs:{"href":_vm.downloadArchive,"download":""}},[_vm._v("Download full package")])]):_vm._e()]:(_vm.source === 'embed' || _vm.isYoutubeSingleGrid)?[_c('p',{directives:[{name:"show",rawName:"v-show",value:(_vm.canDownload),expression:"canDownload"}]},[_c('button',{ref:"embed",attrs:{"data-clipboard-text":_vm.embedUrl}},[_vm._v(" "+_vm._s(_vm.copied ? 'EMBED LINK COPIED' : 'COPY EMBED LINK')+" ")])])]:_vm._e()],2),_c('section',{ref:"content",staticClass:"contentPopin__content",class:{
        'contentPopin__content--grid': _vm.isGrid,
        'contentPopin__content--aspect': !_vm.isGrid,
        // TODO Check if there is only image in grid
        'contentPopin__content--gridImage': _vm.isGrid && _vm.isGridImage,
        'contentPopin__content--gridVideo': _vm.isGrid && _vm.isGridVideo
      },style:(_vm.contentPopinStyle),attrs:{"id":"contentPopin"}},_vm._l((_vm.data),function(item){return _c('a',{key:item.id,staticClass:"contentPopin__item",class:{
          'contentPopin__item--contain': !_vm.isGrid,
          'contentPopin__item--masonry': _vm.useMassonry
        },style:(!_vm.canDownload && _vm.isGrid && 'cursor:pointer!important;'),attrs:{"href":_vm.canDownload &&
            _vm.isGrid &&
            _vm.group.clickItemBehaviour === 'default' &&
            item.media.originFile &&
            item.media.originFile.url,"download":""}},[_c(_vm.itemTag(item.media.type),{ref:"items",refInFor:true,tag:"component",attrs:{"content":item,"autoplay":item.media.type === 'video' && !_vm.isGrid,"in-grid":_vm.isGrid,"file-key":item.media.type === 'video' && !_vm.isGrid
              ? 'originFile'
              : 'popinFile',"mouse-play":"","can-open-popin":!_vm.canDownload},on:{"loaded":_vm.onItemLoaded}})],1)}),0),(!!_vm.mediaText)?_c('p',{staticClass:"contentPopin__mediaDescription"},[_vm._v(" "+_vm._s(_vm.mediaText)+" ")]):_vm._e(),(_vm.hasCredits)?_c('p',{staticClass:"contentPopin__credit"},[_vm._v(" "+_vm._s(_vm.credits)+" ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }